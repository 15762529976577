<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 9.068 8.663">
    <path
      :style="iconStyle"
      d="M15.884,20.12H11.735a.385.385,0,1,1,0-.77h4.149a.385.385,0,1,1,0,.77Z"
      transform="translate(-7.201 -11.457)"
    />
    <path
      :style="iconStyle"
      d="M9.65,2.229a1.363,1.363,0,0,1,.964,2.326L4.851,10.318a.385.385,0,0,1-.179.1l-1.844.461a.385.385,0,0,1-.467-.467L2.823,8.57a.385.385,0,0,1,.1-.179L8.687,2.628A1.354,1.354,0,0,1,9.65,2.229ZM4.382,9.7,10.07,4.011a.593.593,0,1,0-.839-.839L3.543,8.86l-.28,1.118Z"
      transform="translate(-2.35 -2.229)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '19.121',
    },
    width: {
      type: String,
      default: '20',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
